<template>
    <td @click="select" :style="cellStyle">
        <span v-if="!isNotes">{{ cell.value }}</span>
        <div v-if="isNotes" class="notes">
            <div class="note" :style="noteStyle">{{ getNote(1) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(2) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(3) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(4) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(5) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(6) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(7) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(8) }}</div>
            <div class="note" :style="noteStyle">{{ getNote(9) }}</div>
        </div>
    </td>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { Cell } from '../models';
import { boardQuery } from '@/games/sudoku/state/board/board.query';
import { bus } from '@/games/sudoku/bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    props: {
        cellId: String,
        stageWidth: { type: Number, required: true },
    },

    setup(props) {
        // eslint-disable-next-line
        const cell = ref<Cell>(boardQuery.getEntity(props.cellId)!);

        subscribeTo(boardQuery.selectEntity(props.cellId), (c) => {
            if (c) {
                cell.value = c;
            }
        });

        const isNotes = computed(() => {
            return cell.value && cell.value.notes.length > 0;
        });

        const select = () => {
            bus.updateActiveCellCmd$.next({
                cellId: cell.value.id,
            });
        };

        const getNote = (index: number) => {
            if (cell.value.notes.indexOf(index) >= 0) {
                return index.toString();
            }
            return '';
        };

        const cellStyle = computed(() => {
            let bc = null;
            if (cell.value.isSelected) {
                bc = '#bbdefb';
            } else if (cell.value.isConflicted) {
                bc = '#f7cfd6';
            } else if (cell.value.isHighlighted) {
                bc = '#cbdbed';
            } else if (cell.value.isHighlightedAsRange) {
                bc = '#e2e7ed';
            }
            let color = null;
            if (!cell.value.isStatic) {
                color = cell.value.isConflicted ? '#fb3d3f' : '#4a90e2';
            }
            const w = (props.stageWidth - 1) / 9;
            return {
                backgroundColor: bc,
                color,
                width: `${w}px`,
                height: `${w}px`,
                lineHeight: `${w - 1}px`,
                fontSize: `${w * 0.6}px`,
            };
        });

        const noteStyle = computed(() => {
            const w = (props.stageWidth * 0.9) / 9 / 3;
            return {
                width: `${w}px`,
                height: `${w}px`,
                lineHeight: `${w - 1}px`,
                fontSize: `${w * 0.88}px`,
            };
        });

        return {
            cell,
            isNotes,
            select,
            getNote,
            cellStyle,
            noteStyle,
        };
    },
});

/*
@Component
export default class CellView extends Vue {
    @Prop() cellId!: string;

    @Prop() stageWidth!: number;

    cell: Cell = {
        id: '',
        notes: [],
        value: null,
        isStatic: false,
        isConflicted: false,
        isSelected: false,
        isHighlightedAsRange: false,
        isHighlighted: false,
        col: 0,
        row: 0,
        segment: 0
    };

    created() {
        this.cell = boardQuery.getEntity(this.cellId)!;
        this.$subscribeTo(boardQuery.selectEntity(this.cellId), cell => {
            if (cell) {
                this.cell = cell;
            }
        });
    }

    get isNotes() {
        return !this.cell.value && this.cell.notes.length > 0;
    }

    select() {
        bus.updateActiveCellCmd$.next({
            cellId: this.cell.id
        });
    }

    getNote(index: number) {
        if (this.cell.notes.indexOf(index) >= 0) {
            return index.toString();
        }
        return '';
    }

    get cellStyle() {
        let bc = null;
        if (this.cell.isSelected) {
            bc = '#bbdefb';
        } else if (this.cell.isConflicted) {
            bc = '#f7cfd6';
        } else if (this.cell.isHighlighted) {
            bc = '#cbdbed';
        } else if (this.cell.isHighlightedAsRange) {
            bc = '#e2e7ed';
        }
        let color = null;
        if (!this.cell.isStatic) {
            color = this.cell.isConflicted ? '#fb3d3f' : '#4a90e2';
        }
        const w = (this.stageWidth - 1) / 9;
        return {
            backgroundColor: bc,
            color,
            width: `${w}px`,
            height: `${w}px`,
            lineHeight: `${w - 1}px`,
            fontSize: `${w * 0.6}px`
        };
    }

    get noteStyle() {
        const w = (this.stageWidth * 0.9) / 9 / 3;
        return {
            width: `${w}px`,
            height: `${w}px`,
            lineHeight: `${w - 1}px`,
            fontSize: `${w * 0.88}px`
        };
    }
}
 */
</script>

<style scoped>
.notes {
    font-size: 0;
    line-height: 0;
}
.note {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    color: #777;
}
</style>
