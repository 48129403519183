import { Controller } from '@/core/controller';
import { GameController } from '@/games/sudoku/controllers/game.controller';

export class Controllers {
    private controllers: Controller[] = [];

    init() {
        this.controllers = [];
        this.controllers.push(new GameController());
    }

    destroy() {
        this.controllers.forEach((c) => c.destroy());
    }
}

export const controllers = new Controllers();
