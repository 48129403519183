import { Cell } from '@/games/sudoku/models';

export class BoardHistory {
    private boards: Array<Cell[]> = [];

    addItem(board: Cell[]) {
        if (this.boards.length > 100) {
            this.boards.shift();
        }
        const copy = board.map((x) => ({
            ...x,
            notes: [...x.notes],
        }));
        this.boards.push(copy);
    }

    reset() {
        this.boards = [];
    }

    pop() {
        return this.boards.pop();
    }
}

export const boardHistory = new BoardHistory();
