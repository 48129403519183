import { Cell, Difficulty } from '@/games/sudoku/models';
import { boardStore } from '@/games/sudoku/state/board/board.store';
import { boardQuery } from '@/games/sudoku/state/board/board.query';

export class BoardService {
    reset() {
        const row = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
        const col = [1, 2, 3, 4, 5, 6, 7, 8, 9];

        const getSegment = (row: number, col: number) => {
            if (row <= 3 && col <= 3) {
                return 1;
            }
            if (row <= 3 && col >= 4 && col <= 6) {
                return 2;
            }
            if (row <= 3 && col >= 7) {
                return 3;
            }

            if (row >= 4 && row <= 6 && col <= 3) {
                return 4;
            }
            if (row >= 4 && row <= 6 && col >= 4 && col <= 6) {
                return 5;
            }
            if (row >= 4 && row <= 6 && col >= 7) {
                return 6;
            }

            if (row >= 7 && col <= 3) {
                return 7;
            }
            if (row >= 7 && col >= 4 && col <= 6) {
                return 8;
            }
            if (row >= 7 && col >= 7) {
                return 9;
            }
            throw new Error(`invalid row and col: ${row}, ${col}`);
        };

        const cells: Cell[] = [];
        for (let r = 0; r < row.length; r++) {
            for (let c = 0; c < col.length; c++) {
                cells.push({
                    id: `${row[r]}${col[c]}`,
                    row: r + 1,
                    col: c + 1,
                    value: null,
                    notes: [],
                    isConflicted: false,
                    isHighlighted: false,
                    isHighlightedAsRange: false,
                    isSelected: false,
                    isStatic: false,
                    segment: getSegment(r + 1, c + 1),
                });
            }
        }

        boardStore.set(cells);
    }

    setBoard(board: Cell[]) {
        boardStore.set(board);
    }

    setInitialStaticValues(values: { [id: string]: number }) {
        const updates = boardQuery.getAll().map((c) => {
            if (c.id in values) {
                return {
                    ...c,
                    value: values[c.id],
                    isStatic: true,
                };
            }
            return { ...c };
        });
        boardStore.upsertMany(updates);
    }

    updateSelectedCellValue(value: number) {
        const id = boardQuery.getActiveId();
        if (!id) {
            return;
        }
        boardStore.update(id, {
            value,
            notes: [],
        });
    }

    updateActiveCellNotes(notes: number[]) {
        const id = boardQuery.getActiveId();
        if (!id) {
            return;
        }
        boardStore.update(id, {
            value: null,
            notes,
        });
    }

    setCellSelected(id: string) {
        const cell = boardQuery.getEntity(id);
        if (!cell) {
            throw new Error(`invalid cell id: ${id}`);
        }
        const updates = boardQuery.getAll().map((c) => {
            if (c.id == id) {
                return {
                    ...c,
                    isSelected: true,
                    isHighlighted: false,
                    isHighlightedAsRange: false,
                };
            }
            if (c.segment == cell.segment || c.row == cell.row || c.col == cell.col) {
                return {
                    ...c,
                    isSelected: false,
                    isHighlighted: false,
                    isHighlightedAsRange: true,
                };
            }
            if (c.value && cell.value && c.value == cell.value) {
                return {
                    ...c,
                    isSelected: false,
                    isHighlighted: true,
                    isHighlightedAsRange: false,
                };
            }
            return {
                ...c,
                isSelected: false,
                isHighlighted: false,
                isHighlightedAsRange: false,
            };
        });
        boardStore.upsertMany(updates);
        boardStore.setActive(id);
    }

    setConflictedCells(ids: string[]) {
        const updates = boardQuery.getAll().map((c) => {
            const exist = ids.indexOf(c.id) >= 0;
            return {
                ...c,
                isConflicted: exist,
            };
        });
        boardStore.upsertMany(updates);
    }

    eraseActiveCell() {
        const id = boardQuery.getActiveId();
        if (!id) {
            return;
        }
        boardStore.update(id, (s) => ({
            ...s,
            value: s.isStatic ? s.value : null,
            notes: [],
        }));
    }

    setDifficulty(difficulty: Difficulty) {
        boardStore.update({
            difficulty,
        });
    }
}

export const boardService = new BoardService();
