import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Cell, Difficulty } from '@/games/sudoku/models';

export interface BoardState extends EntityState<Cell> {
    difficulty: Difficulty;
}

const initialState = {
    difficulty: Difficulty.easy,
};

@StoreConfig({ name: 'sudoku-board' })
export class BoardStore extends EntityStore<BoardState> {
    constructor() {
        super(initialState);
    }
}

export const boardStore = new BoardStore();
