
import { defineComponent, computed } from 'vue';
import { Difficulty } from '@/games/sudoku/models';
import { gameQuery } from '@/state/game/game.query';
import { useObservable } from '@/core/rxjs-helpers';
import { coreUtil } from '@/core/core-util';
import { boardQuery } from '@/games/sudoku/state/board/board.query';

export default defineComponent({
    setup() {
        const difficulty = useObservable(boardQuery.difficulty$, Difficulty.easy);
        const time = useObservable(
            gameQuery.select((x) => x.gameMetrics.time),
            0
        );

        const timeFormat = computed(() => {
            return coreUtil.timeFormat(time.value);
        });

        return {
            difficulty,
            time,
            timeFormat,
        };
    },
});
