/* eslint-disable */

// This sudoku library is based HEAVILY on Peter Norvig's excellent Sudoku solver,
// available at http://norvig.com/sudoku.html.

// This library contains a solver, generator, serialization of puzzles
// and methods to get conflicts and hints for puzzles that are in progress.
// For a completely straight port of Norvig's solver, look at
// https://github.com/einaregilsson/sudoku.js/sudoku-norvig.js

// To see a better explanation of this library, look at the blog post
// at http://einaregilsson.com/sudoku and to see it in action try
// my Sudoku game at http://www.sudoku-webgame.com

// Start by setting up some basic datastructures and connections
// between them. Each row is numbered from 1-9, each column
// from A-I. Each square has an id like E4.

// Throughout this libarary we will often use the following variable names:

//    d - digit
//    r - row
//    c - column
//    s - square, e.g. E5
//    u - unit, e.g. one whole row, column or box of squares.

import { mapValues } from 'lodash';
import { PredictableRandom } from '@/core/predictable-random';

let random = new PredictableRandom();

const ROWS = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
const COLS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
const DIGITS = '123456789';
const SQUARES: string[] = cross(COLS, ROWS); // Simple list of all squares, [A1, A2, ..., I9]
const UNITLIST: string[][] = []; // List of all units. Each unit contains 9 squares. [ [A1,A2,...A9], [B1,B2,...,B9]...]
const UNITS: { [id: string]: string[][] } = {}; // Units organized by square. UNITS['A1'] = [ ['A1'...'A9'], ['A1'...'I1'], ['A1'...'C3']]
const PEERS: { [id: string]: string[] } = {}; // For each square, the list of other square that share a unit with it. PEERS['A1'] = ['A1', 'A2' ... 'H1','I1']

for (let i = 0; i < ROWS.length; i++) {
    UNITLIST.push(cross(COLS, [ROWS[i]]));
}

for (let i = 0; i < COLS.length; i++) {
    UNITLIST.push(cross([COLS[i]], ROWS));
}

const groupCols = ['ABC', 'DEF', 'GHI'];
const groupRows = ['123', '456', '789'];
for (let c = 0; c < groupCols.length; c++) {
    for (let r = 0; r < groupRows.length; r++) {
        UNITLIST.push(cross(chars(groupCols[c]), chars(groupRows[r])));
    }
}

for (let i = 0; i < SQUARES.length; i++) {
    const square = SQUARES[i],
        squarePeers = [],
        squareUnits = [];

    for (let j = 0; j < UNITLIST.length; j++) {
        const unit = UNITLIST[j];
        if (contains(unit, square)) {
            squareUnits.push(unit);
            for (let k = 0; k < unit.length; k++) {
                if (!contains(squarePeers, unit[k]) && unit[k] !== square) {
                    squarePeers.push(unit[k]);
                }
            }
        }
    }
    UNITS[square] = squareUnits;
    PEERS[square] = squarePeers;
}

///// Utility methods. /////

//Array.indexOf is not supported in old IEs
function vals(obj: any) {
    const result = [];
    for (const key in obj) {
        result.push(obj[key]);
    }
    return result;
}

function keys(obj: any) {
    const result = [];
    for (const key in obj) {
        result.push(key);
    }
    return result;
}

function each(list: any, func: any) {
    filter(list, func);
}

function dict(keys: any, values: any) {
    const result: any = {};
    each(keys, function(i: any, key: any) {
        result[key] = values[i];
    });
    return result;
}

function print(s: any) {
    console.log(s + '\r\n');
}

function all(list: any, func: any) {
    for (let i = 0; i < list.length; i++) {
        if (!func(list[i])) {
            return false;
        }
    }
    return true;
}

function any(list: any, func: any) {
    for (let i = 0; i < list.length; i++) {
        const result = func(list[i]);
        if (result) {
            return result;
        }
    }
    return false;
}

function filter(list: any, func: any) {
    const result = [];
    for (let i = 0; i < list.length; i++) {
        if (func.length > 1) {
            if (func(i, list[i])) {
                result.push(list[i]);
            }
        } else if (func(list[i])) {
            result.push(list[i]);
        }
    }
    return result;
}

function some(seq: any, func: any) {
    //Return some element ofseq that is true.
    for (let i = 0; i < seq.length; i++) {
        const result = func(seq[i]);
        if (result) {
            return result;
        }
    }
    return false;
}

function map(list: any, expr: any) {
    const result: any = [];
    each(list, function(value: any) {
        if (typeof expr === 'function') {
            result.push(expr(value));
        } else if (typeof expr === 'string') {
            result.push(value[expr]);
        }
    });
    return result;
}

function randomElement(list: any) {
    return list[Math.floor(random.next() * list.length)];
}

//Array.indexOf is not supported in old IEs
function contains(list: any, val: any) {
    return any(list, function(x: any) {
        return x === val;
    });
}

function copy(board: any) {
    return dict(keys(board), vals(board));
}

function shuffle(seq: any) {
    //Return a randomly shuffled copy of the input sequence.
    seq = map(seq, function(x: any) {
        return x;
    });
    //Fisher yates shuffle
    let i = seq.length;
    while (--i) {
        const j = Math.floor(random.next() * (i + 1));
        const ival = seq[i];
        const jval = seq[j];
        seq[i] = jval;
        seq[j] = ival;
    }

    return seq;
}

function chars(s: any) {
    const result = [];
    for (let i = 0; i < s.length; i++) {
        result.push(s.charAt(i));
    }
    return result;
}

function cross(a: string[], b: string[]) {
    const result = [];
    for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
            result.push(a[i] + b[j]);
        }
    }
    return result;
}

function getHint(puzzle: any, values: any) {
    if (!values) {
        throw { message: 'Values must be sent in' };
    }
    const solved = solve(puzzle);

    const errorSquares = [];
    // 1. Check if there are any wrong fields, Hint about those first
    for (var s in values) {
        const guess = values[s];
        if (guess && guess !== solved[s]) {
            errorSquares.push(s);
        }
    }

    if (errorSquares.length > 0) {
        return {
            type: 'error',
            square: randomElement(errorSquares)
        };
    }

    // 2. Find a field that has only one possibility and give a hint about that.
    const elimValues: any = {};
    for (var s in solved) {
        elimValues[s] = DIGITS;
    }

    // One round of elimination only
    for (var s in values) {
        elimValues[s] = values[s];
        const digit = values[s];
        var units = UNITS[s];
        for (var i = 0; i < PEERS[s].length; i++) {
            const elimSquare = PEERS[s][i];
            elimValues[elimSquare] = elimValues[elimSquare].replace(digit, '');
        }
    }

    const hintSquares = [];
    for (var s in elimValues) {
        if (elimValues[s].length == 1 && !values[s]) {
            hintSquares.push(s);
        }
    }
    if (hintSquares.length > 0) {
        return {
            type: 'squarehint',
            square: randomElement(hintSquares)
        };
    }

    const unitHints = [];
    // 3. Is there a unit where one digit is only a possibility in one square?
    for (var s in elimValues) {
        const value = elimValues[s];
        if (value.length == 1) {
            continue;
        }
        var units = UNITS[s];
        for (var i = 0; i < value.length; i++) {
            var d = value.charAt(i);
            for (let u = 0; u < units.length; u++) {
                const unit = units[u];
                if (
                    all(unit, function(s2: any) {
                        return s2 == s || elimValues[s2].indexOf(d) == -1;
                    })
                ) {
                    let unitType = 'box';
                    if (unit[0].charAt(0) == unit[8].charAt(0)) {
                        unitType = 'row';
                    } else if (unit[0].charAt(1) == unit[8].charAt(1)) {
                        unitType = 'column';
                    }
                    unitHints.push({
                        type: 'unithint',
                        unitType: unitType,
                        unit: unit,
                        digit: d
                    });
                }
            }
        }
    }

    if (unitHints.length > 0) {
        return randomElement(unitHints);
    }

    return {
        type: 'dontknow',
        squares: elimValues
    };
}

function getConflicts(values: any): { errorFields: string[]; unit: string[] }[] {
    const errors = [];
    for (const key in values) {
        const value = values[key] + '';
        if (!value || value.length > 1) {
            continue;
        }

        for (let i = 0; i < UNITS[key].length; i++) {
            const unit = UNITS[key][i];
            for (let j = 0; j < unit.length; j++) {
                const otherKey = unit[j];
                const otherValue = values[otherKey] + '';

                if (otherKey != key && value == otherValue) {
                    errors.push({
                        unit: unit,
                        errorFields: [key, otherKey]
                    });
                }
            }
        }
    }
    return errors;
}

function solve(grid: string | any, options = {}) {
    return search(parseGrid(grid), options);
}

function search(values: { [id: string]: string } | false, options: any) {
    options = options || {};
    options.chooseDigit = options.chooseDigit || 'random';
    options.chooseSquare = options.chooseSquare || 'minDigits';

    //Using depth-first search and propagation, try all possible values."
    if (values === false) {
        return false; //Failed earlier
    }

    if (
        all(SQUARES, (s: any) => {
            return values[s].length == 1;
        })
    ) {
        return values; // Solved!
    }

    //Chose the unfilled square s with the fewest possibilities
    const candidates = filter(SQUARES, function(s: any) {
        return values[s].length > 1;
    });
    candidates.sort(function(s1, s2) {
        if (values[s1].length != values[s2].length) {
            return values[s1].length - values[s2].length;
        }
        if (s1 < s2) {
            return -1;
        } else {
            return 1;
        }
    });

    let s: any;
    if (options.chooseSquare == 'minDigits') {
        s = candidates[0];
    } else if (options.chooseSquare == 'maxDigits') {
        s = candidates[candidates.length - 1];
    } else if (options.chooseSquare == 'random') {
        s = candidates[Math.floor(random.next() * candidates.length)];
    }

    let digitsLeft = chars(values[s]);
    if (options.chooseDigit == 'max') {
        digitsLeft.reverse();
    } else if (options.chooseDigit == 'random') {
        digitsLeft = shuffle(digitsLeft);
    }

    return some(digitsLeft, (d: any) => {
        return search(assign(copy(values), s, d), options);
    });
}

function isUnique(grid: any) {
    const input = typeof grid === 'string' ? gridValues(grid) : grid;

    const solved1 = solve(input, { chooseDigit: 'min' });
    const solved2 = solve(input, { chooseDigit: 'max' });
    if (!solved1 || !solved2) {
        throw 'Failed to solve';
    }

    for (const s in solved1) {
        if (solved2[s] != solved1[s]) {
            return false;
        }
    }
    return true;
}

function serialize(values: any) {
    let serialized = '';
    for (let i = 0; i < SQUARES.length; i++) {
        serialized += values[SQUARES[i]] || 'x';
    }
    serialized = serialized
        .replace(/xxxxxx/g, 'f')
        .replace(/xxxxx/g, 'e')
        .replace(/xxxx/g, 'd')
        .replace(/xxx/g, 'c')
        .replace(/xx/g, 'b')
        .replace(/x/g, 'a');
    return serialized;
}

function deserialize(serialized: any) {
    const values: any = {};
    serialized = serialized
        .replace(/f/g, 'xxxxxx')
        .replace(/e/g, 'xxxxx')
        .replace(/d/g, 'xxxx')
        .replace(/c/g, 'xxx')
        .replace(/b/g, 'xx')
        .replace(/a/g, 'x');

    for (let i = 0; i < SQUARES.length; i++) {
        if (serialized.charAt(i) != 'x') {
            values[SQUARES[i]] = serialized.charAt(i);
        }
    }
    return values;
}

function isSolvableWithElimination(grid: any) {
    return isSolved(parseGrid(grid));
}

function isSolved(values: any) {
    for (const s in values) {
        if (values[s].length > 1) {
            return false;
        }
    }
    return true;
}

function generate(difficulty: number, seed: number): { [id: string]: number } {
    random = new PredictableRandom(seed);
    const start = new Date().getTime();
    const minSquares = difficulty;

    const fullGrid = solve({});
    const generatedGrid = copy(fullGrid);
    const shuffledSquares = shuffle(SQUARES);
    let filledSquares = shuffledSquares.length;

    for (let i = 0; i < shuffledSquares.length; i++) {
        const s = shuffledSquares[i];

        delete generatedGrid[s];
        filledSquares--;
        if (!isSolvableWithElimination(generatedGrid) || !isUnique(generatedGrid)) {
            generatedGrid[s] = fullGrid[s];
            filledSquares++;
        }

        if (filledSquares === minSquares) {
            break;
        }
    }
    const time = new Date().getTime() - start;
    debug('Generated puzzle with ' + keys(generatedGrid).length + ' squares in ' + time + 'ms');
    return mapValues(generatedGrid, x => parseInt(x, 10));
}

function parseGrid(grid: string | any) {
    //Convert grid to a dict of possible values, {square: digits}, or
    //return false if a contradiction is detected

    // To start, every square can be any digit; then assign values from the grid.
    const values: { [id: string]: string } = {};
    SQUARES.forEach(s => {
        values[s] = DIGITS;
    });

    const input = typeof grid === 'string' ? gridValues(grid) : grid;
    for (const s in input) {
        const d = input[s];
        if (!assign(values, s, d)) {
            return false; // (Fail if we can't assign d to square s.)
        }
    }
    return values;
}

function gridValues(grid: string) {
    //Convert grid into a dict of {square: char} with '0' or '.' for empties.
    grid = grid.replace(/[^0-9\.]/g, '');
    const input: { [id: string]: string } = {};
    for (let i = 0; i < SQUARES.length; i++) {
        const val = grid[i];
        if (DIGITS.indexOf(val) != -1) {
            input[SQUARES[i]] = val;
        }
    }
    return input;
}

//################ Constraint Propagation ################

function assign(values: any, s: any, d: any) {
    //Eliminate all the other values (except d) from values[s] and propagate.
    //Return values, except return false if a contradiction is detected.
    const otherValues = values[s].replace(d, '');
    if (
        all(chars(otherValues), function(d2: any) {
            return eliminate(values, s, d2);
        })
    ) {
        return values;
    } else {
        return false;
    }
}

function eliminate(values: any, s: any, d: any) {
    //Eliminate d from values[s]; propagate when values or places <= 2.
    //return values, except return false if a contradiction is detected.

    if (values[s].indexOf(d) == -1) {
        return values; //Already eliminated
    }

    values[s] = values[s].replace(d, '');
    // (1) If a square s is reduced to one value d2, then eliminate d2 from the peers.
    if (values[s].length == 0) {
        return false; //Contradiction: removed last value
    } else if (values[s].length == 1) {
        const d2 = values[s];
        if (
            !all(PEERS[s], function(s2: any) {
                return eliminate(values, s2, d2);
            })
        ) {
            return false;
        }
    }
    // (2) If a unit u is reduced to only one place for a value d, then put it there.
    for (let i = 0; i < UNITS[s].length; i++) {
        const u = UNITS[s][i];
        const dplaces = filter(u, function(s2: any) {
            return values[s2].indexOf(d) != -1;
        });
        if (dplaces.length == 0) {
            return false; //Contradiction: no place for this value
        } else if (dplaces.length == 1) {
            // d can only be in one place in unit; assign it there
            if (!assign(values, dplaces[0], d)) {
                return false;
            }
        }
    }

    return values;
}

export const sudoku = {
    solve: solve,
    getConflicts: getConflicts,
    getHint: getHint,
    isUnique: isUnique,
    generate: generate,
    serialize: serialize,
    deserialize: deserialize,
    debug: false,
    test: parseGrid,
    unitList: UNITLIST
};

function debug(msg: any) {
    if (sudoku.debug) {
        print(msg);
    }
}
