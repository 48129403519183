import { QueryEntity } from '@datorama/akita';
import { BoardState, boardStore } from '@/games/sudoku/state/board/board.store';

export class BoardQuery extends QueryEntity<BoardState> {
    cells$ = this.selectAll();

    difficulty$ = this.select((state) => state.difficulty);

    getBoardAsDict() {
        const dict: { [id: string]: number } = {};
        this.getAll()
            .filter((x) => !!x.value)
            .forEach((x) => {
                // eslint-disable-next-line
                dict[x.id] = x.value!;
            });
        return dict;
    }

    getActiveCellId(): string | null {
        const active = this.getActiveId();
        return active ? active : null;
    }

    getActiveCell() {
        return this.getEntity(this.getActiveCellId());
    }
}

export const boardQuery = new BoardQuery(boardStore);
