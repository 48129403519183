import { Subject } from 'rxjs';

class Bus {
    updateActiveCellCmd$ = new Subject<UpdateActiveCellCmd>();

    updateActiveCellValueCmd$ = new Subject<UpdateActiveCellValueCmd>();

    eraseActiveCellCmd$ = new Subject();

    historyUndoCmd$ = new Subject();

    updateActiveCellNoteCmd$ = new Subject<UpdateActiveCellNoteCmd>();

    openGameMenu$ = new Subject();
}

export const bus = new Bus();

export type UpdateActiveCellCmd = {
    cellId: string;
};

export type UpdateActiveCellValueCmd = {
    value: number;
};

export type UpdateActiveCellNoteCmd = {
    value: number;
};
