import { bus } from '@/games/sudoku/bus';
import { boardService } from '@/games/sudoku/state/board/board.service';
import { sudoku } from '@/games/sudoku/sudoku';
import { boardQuery } from '@/games/sudoku/state/board/board.query';
import { uniq } from 'lodash';
import { boardHistory } from '@/games/sudoku/history';
import { Difficulty } from '@/games/sudoku/models';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { appQuery } from '@/state/app.query';
import { coreBus, StatsEventType } from '@/core/core-bus';
import { Game, GameStatus } from '@/core/models';
import { tracking } from '@/core/tracking';
import { coreLocalStorage } from '@/core/core-local-storage';
import { BaseGameController } from '@/core/base-game.controller';

export class GameController extends BaseGameController {
    protected initGame(): void {
        boardService.reset();
        boardHistory.reset();

        const game = appQuery.getActiveGame();
        if (game == Game.sudokuEasy) {
            this.createNewBoard(Difficulty.easy);
        } else if (game == Game.sudokuMedium) {
            this.createNewBoard(Difficulty.medium);
        } else if (game == Game.sudokuHard) {
            this.createNewBoard(Difficulty.hard);
        } else if (game == Game.sudokuExpert) {
            this.createNewBoard(Difficulty.expert);
        }
    }

    protected isGameCompleted(): boolean {
        const filledCells = boardQuery.getAll().filter((x) => x.value && x.notes.length == 0);
        if (filledCells.length < 81) {
            return false;
        }

        // check if conflicts
        const dict = boardQuery.getBoardAsDict();
        const errors = sudoku.getConflicts(dict);
        return errors.length <= 0;
    }

    constructor() {
        super();
        this.subscription.add(
            bus.updateActiveCellCmd$.subscribe((cmd) => {
                boardService.setCellSelected(cmd.cellId);
            })
        );
        this.subscription.add(
            bus.updateActiveCellValueCmd$.subscribe((cmd) => {
                this.startGameIfNeeded();
                this.updateCellValue(cmd.value);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.eraseActiveCellCmd$.subscribe(() => {
                this.eraseActiveCell();
            })
        );
        this.subscription.add(
            bus.historyUndoCmd$.subscribe(() => {
                this.historyUndo();
            })
        );
        this.subscription.add(
            bus.updateActiveCellNoteCmd$.subscribe((cmd) => {
                this.startGameIfNeeded();
                this.updateNotes(cmd.value);
            })
        );
        this.subscription.add(
            gameQuery.gameStatus$.subscribe((status) => {
                if (status == GameStatus.running) {
                    this.startOrResumeTime();
                } else {
                    this.pauseTime();
                }
                if (status == GameStatus.paused) {
                    coreBus.showPauseOverlay$.next();
                }
            })
        );
        this.subscription.add(
            appQuery.isAppHidden$.subscribe((hidden) => {
                if (hidden && gameQuery.getValue().gameStatus == GameStatus.running) {
                    gameService.setGameStatus(GameStatus.paused);
                }
            })
        );
        this.subscription.add(
            coreBus.resumeClickedEvent$.subscribe(() => {
                gameService.setGameStatus(GameStatus.running);
            })
        );
    }

    private async createNewBoard(difficulty: Difficulty) {
        const gameId = gameQuery.getValue().gameId;
        if (!gameId) {
            throw new Error('missing gameId');
        }

        boardService.setDifficulty(difficulty);

        // get board
        boardService.reset();
        setTimeout(() => {
            const board = sudoku.generate(this.getNumericDifficulty(difficulty), gameId);
            boardService.setInitialStaticValues(board);
            gameService.setGameStatus(GameStatus.readyToDeal);
        });
    }

    private startGameIfNeeded() {
        // we only start the game after user made first move
        if (gameQuery.getValue().gameStatus == GameStatus.readyToDeal) {
            gameService.setGameStatus(GameStatus.running);
            coreLocalStorage.setGameInProgress(true, appQuery.getActiveGame());
            coreBus.statsEvent$.next({
                game: appQuery.getActiveGame(),
                type: StatsEventType.gameStated,
            });
            tracking.event('game-started', {
                eventCategory: appQuery.getActiveGame().toString(),
            });
        }
    }

    private getNumericDifficulty(difficulty: Difficulty) {
        switch (difficulty) {
            case Difficulty.easy:
                return process.env.NODE_ENV === 'development' ? 80 : 37;
            case Difficulty.medium:
                return 30;
            case Difficulty.hard:
                return 25;
            case Difficulty.expert:
                return 23;
        }
        throw new Error('invalid difficulty');
    }

    private updateCellValue(value: number) {
        const cell = boardQuery.getActiveCell();
        if (!cell || cell.isStatic) {
            return;
        }
        this.updateHistory();
        boardService.updateSelectedCellValue(value);
        this.updateConflicts();
    }

    private eraseActiveCell() {
        const cell = boardQuery.getActiveCell();
        if (!cell || cell.isStatic) {
            return;
        }
        this.updateHistory();
        boardService.eraseActiveCell();
        this.updateConflicts();
    }

    private updateNotes(value: number) {
        const cell = boardQuery.getActiveCell();
        if (!cell || cell.isStatic) {
            return;
        }
        this.updateHistory();

        // if note already exist we want to remove it
        if (cell.notes.indexOf(value) >= 0) {
            const notes = cell.notes.filter((x) => x != value);
            boardService.updateActiveCellNotes(notes);
        } else {
            const notes = [...cell.notes];
            notes.push(value);
            boardService.updateActiveCellNotes(notes);
        }

        this.updateConflicts();
    }

    private historyUndo() {
        const board = boardHistory.pop();
        if (board) {
            boardService.setBoard(board);
        }
    }

    private updateConflicts() {
        const dict = boardQuery.getBoardAsDict();
        const errors = uniq(sudoku.getConflicts(dict).flatMap((x) => x.errorFields));
        boardService.setConflictedCells(errors);
    }

    private updateHistory() {
        const all = boardQuery.getAll();
        boardHistory.addItem(all);
    }

    protected handleResize() {
        console.log('--handle resize sudoku');
    }
}
