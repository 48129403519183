<template>
    <div class="game-wrapper flex-grow">
        <status-panel />
        <div ref="gameStage" class="game-stage" v-show="!showGameCompleteView">
            <table class="noselect">
                <tr v-for="row in ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']" :key="row">
                    <cell-view :cell-id="`${row}1`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}2`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}3`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}4`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}5`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}6`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}7`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}8`" :stage-width="stageWidth"></cell-view>
                    <cell-view :cell-id="`${row}9`" :stage-width="stageWidth"></cell-view>
                </tr>
            </table>

            <div class="mt-3 mb-2 number-buttons">
                <s-button :outlined="true" color="white" @click="setValue(1)">1</s-button>
                <s-button :outlined="true" color="white" @click="setValue(2)">2</s-button>
                <s-button :outlined="true" color="white" @click="setValue(3)">3</s-button>
                <s-button :outlined="true" color="white" @click="setValue(4)">4</s-button>
                <s-button :outlined="true" color="white" @click="setValue(5)">5</s-button>
                <s-button :outlined="true" color="white" @click="setValue(6)">6</s-button>
                <s-button :outlined="true" color="white" @click="setValue(7)">7</s-button>
                <s-button :outlined="true" color="white" @click="setValue(8)">8</s-button>
                <s-button :outlined="true" color="white" @click="setValue(9)">9</s-button>
            </div>

            <div class="text-center">
                <bottom-nav-button @click="undo" icon="undo" label="Undo"></bottom-nav-button>
                <bottom-nav-button @click="erase" icon="eraser" label="Erase"></bottom-nav-button>
                <bottom-nav-button
                    @click="toggleNote"
                    icon="pencil"
                    label="Notes"
                    class="notes"
                    :class="{ active: isNoteOn }"
                ></bottom-nav-button>
            </div>
        </div>
        <game-complete-panel v-if="showGameCompleteView" />
        <action-panel :hide-undo="true" />
        <div
            v-show="!showGameCompleteView"
            style="position: fixed; bottom: 60px; font-size: 80%; color: #aaa"
        >
            Game: #{{ gameId }}
        </div>
        <game-menu
            v-model="showGameMenu"
            :game="game"
            :game-id="gameId"
            :game-in-progress="isGameInProgress"
            @start-new-game="startNewGame"
            @stats-click="statsClicked"
            :hide-daily-challenge="hideDailyChallenge"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { boardService } from '@/games/sudoku/state/board/board.service';
import { Cell } from '@/games/sudoku/models';
import { boardQuery } from '@/games/sudoku/state/board/board.query';
import { isNaN, keyBy } from 'lodash';
import { bus } from '@/games/sudoku/bus';
import { controllers } from '@/games/sudoku/controllers/controllers';
import CellView from '@/games/sudoku/views/CellView.vue';
import StatusPanel from '@/games/sudoku/views/StatusPanel.vue';
import { appService } from '@/state/app.service';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { coreUtil } from '@/core/core-util';
import confetti from 'canvas-confetti';
import GameCompletePanel from '@/components/GameCompletePanel.vue';
import ActionPanel from '@/components/ActionPanel.vue';
import SButton from '@/components/SButton.vue';
import BottomNavButton from '@/components/BottomNavButton.vue';
import { appQuery } from '@/state/app.query';
import GameMenu from '@/components/GameMenu.vue';
import { coreBus } from '@/core/core-bus';
import { useRoute } from 'vue-router';
import { subscribeTo } from '@/core/rxjs-helpers';
import { GameStatus } from '@/core/models';

export default defineComponent({
    components: {
        CellView,
        SButton,
        BottomNavButton,
        StatusPanel,
        ActionPanel,
        GameMenu,
        GameCompletePanel,
    },

    setup() {
        const board = ref<{ [id: string]: Cell }>({});
        const stageWidth = ref(0);
        const isNoteOn = ref(false);
        const gameId = ref(0);
        const gameStatus = ref(GameStatus.none);
        const showGameMenu = ref(false);
        const showGameCompleteView = ref(false);
        const gameStage = ref<HTMLElement | null>(null);
        const route = useRoute();

        boardService.reset();

        subscribeTo(boardQuery.cells$, (cells) => {
            board.value = keyBy(cells, (c) => c.id);
        });

        subscribeTo(coreBus.openGameMenu$, () => {
            showGameMenu.value = true;
        });

        const handleGameCompleted = async () => {
            // first show board complete animation

            // show the complete panel and
            await coreUtil.delay(1000);
            showGameCompleteView.value = true;

            // show confetti
            await coreUtil.delay(500);
            confetti({
                particleCount: 120,
                spread: 60,
            });
        };

        subscribeTo(gameQuery.gameStatus$, (status) => {
            gameStatus.value = status;
            if (status == GameStatus.gameCompleted) {
                handleGameCompleted();
            } else {
                showGameCompleteView.value = false;
            }
        });

        subscribeTo(
            gameQuery.select((s) => s.gameId),
            (id) => {
                gameId.value = id || 0;
            }
        );

        const game = computed(() => {
            return appQuery.getActiveGame();
        });

        const initNewGame = () => {
            const sid = route.params.gameId as string | undefined;
            const gameId = sid && !isNaN(sid) ? parseInt(sid, 10) : null;
            coreBus.startGameCmd$.next({
                gameId,
                game: game.value,
            });
        };

        const setValue = (val: number) => {
            if (isNoteOn.value) {
                bus.updateActiveCellNoteCmd$.next({
                    value: val,
                });
            } else {
                bus.updateActiveCellValueCmd$.next({
                    value: val,
                });
            }
        };

        const erase = () => {
            bus.eraseActiveCellCmd$.next();
        };

        const undo = () => {
            bus.historyUndoCmd$.next();
        };

        const toggleNote = () => {
            isNoteOn.value = !isNoteOn.value;
            console.log('----notes', isNoteOn.value);
        };

        const updateDims = () => {
            const container = gameStage.value as HTMLElement;
            stageWidth.value = container.clientWidth;
        };

        const isGameInProgress = computed(() => {
            return gameStatus.value == GameStatus.running || gameStatus.value == GameStatus.paused;
        });

        const startNewGame = (gameId: number | null) => {
            coreBus.startGameCmd$.next({
                gameId,
                game: game.value,
            });
        };

        const statsClicked = () => {
            console.log('----stats open', game.value);
            coreBus.showGameStatisticDialogCmd$.next({
                game: game.value,
                hideMoveData: true,
                hideScoreData: true,
            });
        };

        onMounted(() => {
            console.log('---mounted');
            controllers.init();
            updateDims();
            setTimeout(() => {
                initNewGame();
            });
        });

        onBeforeUnmount(() => {
            controllers.destroy();
            gameService.setGameStatus(GameStatus.none);
            appService.setDisablePageScroll(false);
        });

        const hideDailyChallenge = computed(() => {
            return game.value != 'sudoku-easy';
        });

        return {
            board,
            stageWidth,
            isNoteOn,
            showGameCompleteView,
            showGameMenu,
            gameStage,
            setValue,
            erase,
            undo,
            toggleNote,
            game,
            isGameInProgress,
            startNewGame,
            statsClicked,
            gameId,
            hideDailyChallenge,
        };
    },
});
</script>

<style scoped>
.game-wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 500px;
}
.game-stage {
    width: 100%;
}

table {
    border-collapse: collapse;
}

table td {
    border-right: 1px solid #bec6d4;
    border-bottom: 1px solid #bec6d4;
    background-color: #f8f8f8;
    color: #344861;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

table td:hover {
    background-color: #def;
}

table td:nth-child(3),
table td:nth-child(6) {
    border-right: 2px solid #344861;
}

table tr:nth-child(3) td,
table tr:nth-child(6) td {
    border-bottom: 2px solid #344861;
}
.number-buttons {
    display: flex;
    justify-content: space-between;
}
.number-buttons button {
    min-width: 10%;
    padding: 0;
    height: 36px;
}
.theme--dark.v-bottom-navigation .v-btn:not(.v-btn--active) {
    color: rgb(255 255 255) !important;
}
.notes.active {
    background-color: #00acc1;
}
</style>
