export type Cell = {
    id: string;
    value: number | null;
    row: number;
    col: number;
    segment: number;
    notes: number[];
    isStatic: boolean;
    isSelected: boolean;
    isHighlighted: boolean;
    isConflicted: boolean;
    isHighlightedAsRange: boolean;
};

export enum Difficulty {
    easy = 'easy',
    medium = 'medium',
    hard = 'hard',
    expert = 'expert',
}
